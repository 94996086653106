import { ITileService, ITileServiceFields } from 'types/ContentfulTypes';
import Typography from '../../../Typography/Typography';
import ContentfulFieldRenderer from '../../ContentfulFieldRenderer/ContentfulFieldRenderer';
import {
  StyledIcon,
  StyledLinkWrapper,
  StyledRendererWrapper,
  StyledTileServiceWrapper,
} from './ContentfulTileService.styled';

interface AdditionalProps {
  colorIndex: number;
}

interface BodyProps {
  additionalProps: AdditionalProps;
  className?: string;
  content: ITileServiceFields['link'];
}

const Body = ({ additionalProps, className = '', content }: BodyProps) => {
  const { content: tileContent, icon, title } = content.fields;

  if (!tileContent) {
    return null;
  }

  return (
    <StyledTileServiceWrapper className={className} colorIndex={additionalProps?.colorIndex} hasIcon={!!icon}>
      <StyledIcon content={icon} preventLink size={200} />
      <Typography fontSize={125} fontWeight="normal" marginBottom={100} tag="h4" textAlign="center">
        {title}
      </Typography>
      <StyledRendererWrapper>
        <ContentfulFieldRenderer className="tile-service-content" richTextContent={tileContent} />
      </StyledRendererWrapper>
    </StyledTileServiceWrapper>
  );
};

interface ContentfulTileServiceProps {
  additionalProps: AdditionalProps;
  content: ITileService;
}

const ContentfulTileService = ({ additionalProps, content }: ContentfulTileServiceProps) => {
  const { link } = content.fields;

  if (link) {
    return (
      <StyledLinkWrapper link={link}>
        <Body additionalProps={additionalProps} className="link-children" content={content} />
      </StyledLinkWrapper>
    );
  }

  return <Body additionalProps={additionalProps} content={content} />;
};

export default ContentfulTileService;
