import ContentfulFieldRenderer from 'components/Contentful/ContentfulFieldRenderer/ContentfulFieldRenderer';
import Typography from 'components/Typography/Typography';
import styled, { css } from 'styled-components';
import { breakpointDown, breakpointUp, color, spacer } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Flex from '../../../Flex/Flex';
import ContentfulIcon from '../ContentfulIcon/ContentfulIcon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

export const StyledRow = styled(Flex)`
  ${breakpointDown(
    BREAKPOINTS.LG,
    css`
      --bs-gutter-x: ${spacer(75)};
      --bs-gutter-y: ${spacer(75)};
    `
  )}

  ${breakpointDown(
    BREAKPOINTS.SM,
    css`
      --bs-gutter-x: ${spacer(25)};
      --bs-gutter-y: ${spacer(25)};
    `
  )}
`;

interface StyledServicesBannerProps {
  amountOfItems?: number;
}

export const StyledServicesBanner = styled.div<StyledServicesBannerProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${({ amountOfItems }) => amountOfItems}, 75%);
  align-items: center;
  gap: ${spacer(100)};
  padding: ${spacer(100)} ${spacer(125)};
  background-color: ${color('whisper-100')};
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledServicesBannerProps>`
      grid-template-columns: repeat(${({ amountOfItems }) => amountOfItems}, 66%);
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.LG,
    css<StyledServicesBannerProps>`
      margin: 0;
      width: 100%;
      overflow-x: auto;
      border-radius: ${spacer(50)};
      display: flex;
      justify-content: center;
      gap: ${({ amountOfItems = 0 }) => (amountOfItems > 3 ? spacer(300) : spacer(500))};
      padding: ${spacer(200)};
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.XL,
    css<StyledServicesBannerProps>`
      gap: ${({ amountOfItems = 0 }) => (amountOfItems > 3 ? spacer(300) : spacer(650))};
    `
  )}
`;

export const StyledServicesBannerItem = styled(ContentfulPageLink)`
  display: flex;
  gap: ${spacer(100)};
  align-items: center;

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      width: auto;
      max-width: 17rem;
    `
  )}
`;

export const StyledServicesBannerItemIcon = styled(ContentfulIcon)`
  .icon {
    width: ${spacer(150)};
    height: ${spacer(150)};

    ${breakpointUp(
      BREAKPOINTS.MD,
      css`
        width: ${spacer(200)};
        height: ${spacer(200)};
      `
    )}
  }
`;

export const StyledServicesBannerItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledServicesBannerItemTitle = styled(Typography)`
  font-size: ${spacer(88)};
  line-height: ${spacer(112)};
  font-weight: 700;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      font-size: ${spacer(100)};
    `
  )}
`;

export const StyledServicesBannerItemDescription = styled(ContentfulFieldRenderer)`
  font-size: ${spacer(75)};
  margin-bottom: 0;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      font-size: ${spacer(88)};
    `
  )}
`;
