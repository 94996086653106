import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme/functions';
import ContentfulIcon from '../ContentfulIcon/ContentfulIcon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

const backgroundColors = ['#ffd3c3', '#fcd0cb', '#dcf3e9', '#bfe7f8'];

export const StyledLinkWrapper = styled(ContentfulPageLink)`
  text-decoration: none;

  .link-children {
    position: relative;
    cursor: pointer;

    &::before {
      transition: opacity 250ms ease;
      border-radius: ${variable('border-radius')};
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: 0%;
      z-index: 1;
    }

    &.active,
    &:hover,
    &:active {
      &::before {
        opacity: 10%;
      }
    }
  }
`;

interface StyledTileServiceWrapperProps {
  colorIndex: number;
  hasIcon: boolean;
}

export const StyledTileServiceWrapper = styled.div<StyledTileServiceWrapperProps>`
  display: flex;
  border-radius: ${variable('border-radius')};
  padding: ${spacer(200)} ${spacer(100)} ${spacer(150)};
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${({ colorIndex }) => backgroundColors[colorIndex]};
  padding-top: ${({ hasIcon }) => !hasIcon && spacer(300)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      padding: ${spacer(400)} ${spacer(100)} ${spacer(300)};
    `
  )};
`;

export const StyledIcon = styled(ContentfulIcon)`
  margin-bottom: ${spacer(100)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      margin-bottom: ${spacer(250)};

      .icon {
        width: ${spacer(300)};
        height: ${spacer(300)};
      }
    `
  )};
`;

export const StyledRendererWrapper = styled.div`
  // ContentfulFieldRenderer doesn't handle styled components well
  .tile-service-content {
    color: black;
    text-align: center;
  }
`;
