import { IGroupingServices } from 'types/ContentfulTypes';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulTileService from '../ContentfulTileService/ContentfulTileService';
import {
  StyledServicesBanner,
  StyledServicesBannerItem,
  StyledServicesBannerItemContent,
  StyledServicesBannerItemDescription,
  StyledServicesBannerItemIcon,
  StyledServicesBannerItemTitle,
} from './ContentfulGroupingServices.styled';

interface ContentfulGroupingServicesProps {
  content: IGroupingServices;
}

const ContentfulGroupingServices = ({ content }: ContentfulGroupingServicesProps) => {
  const { items, showAsBanner, title } = content.fields;

  if (!items?.length) {
    return null;
  }

  if (showAsBanner) {
    return (
      <StyledServicesBanner amountOfItems={items?.length || 0}>
        {items?.map((item) => {
          const { content, icon, link, title } = item.fields;
          return (
            <StyledServicesBannerItem key={item.sys.id} link={link}>
              <StyledServicesBannerItemIcon content={icon} color="primary-300" />
              <StyledServicesBannerItemContent>
                <StyledServicesBannerItemTitle>{title}</StyledServicesBannerItemTitle>
                <StyledServicesBannerItemDescription richTextContent={content} />
              </StyledServicesBannerItemContent>
            </StyledServicesBannerItem>
          );
        })}
      </StyledServicesBanner>
    );
  }

  return (
    <ContentfulGroupingWrapper>
      <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>
      <div className="grid grid-cols-2 gap-3 lg:grid-cols-4 lg:gap-4">
        {items?.map((item, index) => (
          <ContentfulTileService key={item.sys.id} additionalProps={{ colorIndex: index }} content={item} />
        ))}
      </div>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingServices;
